import { useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import AOS from 'aos'
import 'aos/dist/aos.css'
import Carrousel from '../../components/Carrousel-Costumers'
import Slider from 'react-slick'

import costu1 from '../../img/Chedraui.png'
import costu2 from '../../img/logoHeb.png'
import costu3 from '../../img/Cemaco.png'
import costu4 from '../../img/Speedo-Logo.png'
import costu5 from '../../img/elektra.png' /* Tork 1.png */
import costu7 from '../../img/Riskified.png'
import costu9 from '../../img/coppel-pay.png' /* Alebripets.png */
import costu13 from '../../img/Flywire.png' /* Jobst_logo.png */
import costu14 from '../../img/Digital River.png' /* GoJiraf.png */
import costu15 from '../../img/PayPal.png'
import costu19 from '../../img/champion-logo 1.png'
import costu22 from '../../img/Walmart.png'
import costu23 from '../../img/La Comer.png'
import costu25 from '../../img/Farmavita.png'
import costu27 from '../../img/Old Navy.png'
import costu28 from '../../img/Madisa.png'
import costu29 from '../../img/Nadro.png'
import costu30 from '../../img/BAC.png'
import costu31 from '../../img/Elektra-gt.png'
import costu33 from '../../img/City Market.png'
import costu34 from '../../img/Ekt Honduras.png'
import costu36 from '../../img/la marina.png'
import costu37 from '../../img/El Bodegón.png'
import costu38 from '../../img/KueskiPay.png'
import costu39 from '../../img/Office Max.png'
import costu40 from '../../img/VS.png'
import costu41 from '../../img/Signifyd.png'
import costu42 from '../../img/Fresko.png'
import costu45 from '../../img/B&O.png'
import costu46 from '../../img/RadioShack.png'
import costu48 from '../../img/Sumesa.png'
import costu49 from '../../img/Zucarmex.png'
import costu50 from '../../img/Yuno.png'
import costu51 from '../../img/Mi tienda del ahorro.png'
import costu52 from '../../img/Gandhi.png'
import costu54 from '../../img/amv.png'
import costu55 from '../../img/ck.png'
import costu56 from '../../img/bbw.jpg'
import costu57 from '../../img/guess.webp'
import costu58 from '../../img/tommy.png'
import costu59 from '../../img/cloe_logo.png'
import costu60 from '../../img/similares.png'
import costu61 from '../../img/stripe.svg'
import costu62 from '../../img/lego.png'
import costu63 from '../../img/juguetron.jpeg'
import costu64 from '../../img/office_nuevo.jpg'
import costu65 from '../../img/pefsa.png'
import costu66 from '../../img/cemex.png'

const Technologies = () => {
  const intl = useIntl()
  AOS.init()
  const images = [
    'exito_heb.png',
    'exito_gandhi.png',
    'exito_nadro.png',
    'exito_axo.png',
  ]
  const settings = {
    dots: false,
    infinite: true,
    autoplay: true,
    speed: 4000,
    autoplaySpeed: 3000,
    cssEase: 'linear',
    slidesToShow: 6,
    slidesToScroll: 1,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 2,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
    ],
  }
  const settings2 = {
    rtl: true,
    dots: false,
    infinite: true,
    autoplay: true,
    speed: 4000,
    autoplaySpeed: 3000,
    cssEase: 'linear',
    slidesToShow: 6,
    slidesToScroll: 1,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 2,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
    ],
  }

  const [screenSizeWidth, setScreenSizeWidth] = useState(window.screen.width)
  useEffect(() => {
    setScreenSizeWidth(window.screen.width)
  }, [])
  
  return (
    <div className="sec-costumers">
      <div id="costumers" style={{ height: '100px' }}></div>
      <div className="sec-title">
        <h2 data-aos="slide-up" data-aos-duration="1000" data-aos-once="true">
          {intl.formatMessage({
            id: 'app.pages.home.sectionCostumers.label.title',
          })}
        </h2>
        <h3>
          {intl.formatMessage({
            id: 'app.pages.home.sectionCostumers.label.subtitle',
          })}
        </h3>
      </div>
      <div className="sec-content costumers">
        <Carrousel images={images} autoPlay={screenSizeWidth > 768} showButtons={true} />
      </div>
      <div className="sec-more-costumers">
        <h3>
          {intl.formatMessage({
            id: 'app.pages.home.sectionCostumers.label.subtitle2',
          })}
        </h3>
        {window.screen.width > 1080 ? (
          <>
            <div className="costumers1">
              <img src={costu2} alt="costu2" style={{ maxWidth: '173px' }} />
              <img src={costu1} alt="costu1" style={{ maxWidth: '90px' }} />
              <img src={costu22} alt="costu22" style={{ maxWidth: '185px' }} />
              <img src={costu23} alt="costu23" style={{ maxWidth: '158px' }} />
              <img src={costu65} alt="Pefsa" style={{ maxWidth: '66px' }} />
              <img src={costu5} alt="costu5" style={{ maxWidth: '158px' }} />
              <img src={costu7} alt="costu7" style={{ maxWidth: '160px' }} />
            </div>

            <div className="costumers2">
              <img src={costu9} alt="costu9" style={{ maxWidth: '162px' }} />
              <img src={costu34} alt="costu34" style={{ maxWidth: '158px' }} />
              <img src={costu66} alt="Cemex" style={{ maxWidth: '146px' }} />
              <img src={costu25} alt="costu25" style={{ maxWidth: '183px' }} />
              <img
                src={costu64}
                alt="Office Depot"
                style={{ maxWidth: '126px' }}
              />
              <img
                src={costu27}
                alt="costu27"
                style={{ maxWidth: '160px', height: '50px' }}
              />
              <img src={costu14} alt="costu14" style={{ maxWidth: '150px' }} />
            </div>

            <div className="costumers2">
              <img src={costu28} alt="costu28" style={{ maxWidth: '162px' }} />
              <img src={costu3} alt="costu3" style={{ maxWidth: '158px' }} />
              <img src={costu29} alt="costu29" style={{ maxWidth: '146px' }} />
              <img src={costu30} alt="costu30" style={{ maxWidth: '183px' }} />
              <img src={costu31} alt="costu31" style={{ maxWidth: '155px' }} />
              <img src={costu19} alt="costu19" style={{ maxWidth: '160px' }} />
              <img src={costu54} alt="costu54" style={{ maxWidth: '150px' }} />
            </div>

            <div className="costumers2">
              <img src={costu33} alt="costu33" style={{ maxWidth: '162px' }} />
              <img src={costu15} alt="costu15" style={{ maxWidth: '158px' }} />
              <img src={costu55} alt="costu55" style={{ maxWidth: '85px' }} />
              <img src={costu56} alt="costu56" style={{ maxWidth: '183px' }} />
              <img src={costu57} alt="costu57" style={{ maxWidth: '155px' }} />
              <img src={costu36} alt="costu36" style={{ maxWidth: '160px' }} />
              <img src={costu37} alt="costu37" style={{ maxWidth: '150px' }} />
            </div>

            <div className="costumers2">
              <img src={costu13} alt="costu13" style={{ maxWidth: '162px' }} />
              <img src={costu39} alt="costu39" style={{ maxWidth: '158px' }} />
              <img src={costu40} alt="costu40" style={{ maxWidth: '146px' }} />
              <img src={costu41} alt="costu41" style={{ maxWidth: '183px' }} />
              <img src={costu58} alt="costu58" style={{ maxWidth: '180px' }} />
              <img src={costu42} alt="costu42" style={{ maxWidth: '160px' }} />
              <img src={costu38} alt="costu38" style={{ maxWidth: '150px' }} />
            </div>

            <div className="costumers2">
              <img src={costu59} alt="costu59" style={{ maxWidth: '162px' }} />
              <img src={costu15} alt="costu15" style={{ maxWidth: '158px' }} />
              <img src={costu4} alt="costu4" style={{ maxWidth: '146px' }} />
              <img src={costu62} alt="Lego" style={{ maxWidth: '158px' }} />
              <img src={costu45} alt="costu45" style={{ maxWidth: '155px' }} />
              <img src={costu46} alt="costu46" style={{ maxWidth: '160px' }} />
              <img src={costu60} alt="costu60" style={{ maxWidth: '180px' }} />
            </div>

            <div className="costumers2">
              <img src={costu48} alt="costu48" style={{ maxWidth: '162px' }} />
              <img src={costu49} alt="costu49" style={{ maxWidth: '158px' }} />
              <img
                src={costu63}
                alt="Juguetron"
                style={{ maxWidth: '175px' }}
              />
              <img src={costu50} alt="costu50" style={{ maxWidth: '163px' }} />
              <img src={costu51} alt="costu51" style={{ maxWidth: '155px' }} />
              <img src={costu52} alt="costu52" style={{ maxWidth: '160px' }} />
              <img src={costu61} alt="Stripe" style={{ maxWidth: '140px' }} />
            </div>
          </>
        ) : (
          <>
            <div className="container" style={{ width: '93%' }}>
              <br />
              <Slider {...settings}>
                <div className="carousel-slide">
                  <img
                    src={costu2}
                    alt="costu2"
                    style={{ maxWidth: '135px' }}
                  />
                </div>
                <div className="carousel-slide">
                  <img src={costu1} alt="costu1" style={{ maxWidth: '75px' }} />
                </div>
                <div className="carousel-slide">
                  <img
                    src={costu22}
                    alt="costu22"
                    style={{ maxWidth: '135px' }}
                  />
                </div>
                <div className="carousel-slide">
                  <img
                    src={costu23}
                    alt="costu23"
                    style={{ maxWidth: '135px' }}
                  />
                </div>
                <div className="carousel-slide">
                  <img
                    src={costu65}
                    alt="costu65"
                    style={{ maxWidth: '135px' }}
                  />
                </div>
                <div className="carousel-slide">
                  <img
                    src={costu5}
                    alt="costu5"
                    style={{ maxWidth: '135px' }}
                  />
                </div>
                <div className="carousel-slide">
                  <img
                    src={costu7}
                    alt="costu7"
                    style={{ maxWidth: '135px' }}
                  />
                </div>
                <div className="carousel-slide">
                  <img
                    src={costu9}
                    alt="costu9"
                    style={{ maxWidth: '135px' }}
                  />
                </div>
                <div className="carousel-slide">
                  <img
                    src={costu34}
                    alt="costu34"
                    style={{ maxWidth: '135px' }}
                  />
                </div>
                <div className="carousel-slide">
                  <img
                    src={costu66}
                    alt="costu66"
                    style={{ maxWidth: '100px' }}
                  />
                </div>
                <div className="carousel-slide">
                  <img
                    src={costu25}
                    alt="costu25"
                    style={{ maxWidth: '135px' }}
                  />
                </div>
                <div className="carousel-slide">
                  <img
                    src={costu64}
                    alt="costu64"
                    style={{ maxWidth: '90px' }}
                  />
                </div>
                <div className="carousel-slide">
                  <img
                    src={costu27}
                    alt="costu27"
                    style={{ maxWidth: '135px' }}
                  />
                </div>
                <div className="carousel-slide">
                  <img
                    src={costu14}
                    alt="costu14"
                    style={{ maxWidth: '135px' }}
                  />
                </div>
                <div className="carousel-slide">
                  <img
                    src={costu28}
                    alt="costu28"
                    style={{ maxWidth: '135px' }}
                  />
                </div>
                <div className="carousel-slide">
                  <img
                    src={costu3}
                    alt="costu3"
                    style={{ maxWidth: '135px' }}
                  />
                </div>
                <div className="carousel-slide">
                  <img
                    src={costu29}
                    alt="costu29"
                    style={{ maxWidth: '135px' }}
                  />
                </div>
                <div className="carousel-slide">
                  <img
                    src={costu30}
                    alt="costu30"
                    style={{ maxWidth: '135px' }}
                  />
                </div>
                <div className="carousel-slide">
                  <img
                    src={costu31}
                    alt="costu31"
                    style={{ maxWidth: '135px' }}
                  />
                </div>
                <div className="carousel-slide">
                  <img
                    src={costu19}
                    alt="costu19"
                    style={{ maxWidth: '135px' }}
                  />
                </div>
                <div className="carousel-slide">
                  <img
                    src={costu54}
                    alt="costu54"
                    style={{ maxWidth: '135px' }}
                  />
                </div>
                <div className="carousel-slide">
                  <img
                    src={costu33}
                    alt="costu33"
                    style={{ maxWidth: '135px' }}
                  />
                </div>
                <div className="carousel-slide">
                  <img
                    src={costu15}
                    alt="costu15"
                    style={{ maxWidth: '135px' }}
                  />
                </div>
                <div className="carousel-slide">
                  <img
                    src={costu55}
                    alt="costu55"
                    style={{ maxWidth: '100px' }}
                  />
                </div>
                <div className="carousel-slide">
                  <img
                    src={costu56}
                    alt="costu56"
                    style={{ maxWidth: '100px' }}
                  />
                </div>
                <div className="carousel-slide">
                  <img
                    src={costu57}
                    alt="costu57"
                    style={{ maxWidth: '100px' }}
                  />
                </div>
              </Slider>
              <br />
              <Slider {...settings2}>
                <div className="carousel-slide">
                  <img
                    src={costu36}
                    alt="costu36"
                    style={{ maxWidth: '135px' }}
                  />
                </div>
                <div className="carousel-slide">
                  <img
                    src={costu37}
                    alt="costu37"
                    style={{ maxWidth: '135px' }}
                  />
                </div>
                <div className="carousel-slide">
                  <img
                    src={costu13}
                    alt="costu13"
                    style={{ maxWidth: '135px' }}
                  />
                </div>
                <div className="carousel-slide">
                  <img
                    src={costu39}
                    alt="costu39"
                    style={{ maxWidth: '135px' }}
                  />
                </div>
                <div className="carousel-slide">
                  <img
                    src={costu40}
                    alt="costu40"
                    style={{ maxWidth: '135px' }}
                  />
                </div>
                <div className="carousel-slide">
                  <img
                    src={costu41}
                    alt="costu41"
                    style={{ maxWidth: '135px' }}
                  />
                </div>
                <div className="carousel-slide">
                  <img
                    src={costu58}
                    alt="costu58"
                    style={{ maxWidth: '180px' }}
                  />
                </div>
                <div className="carousel-slide">
                  <img
                    src={costu42}
                    alt="costu42"
                    style={{ maxWidth: '135px' }}
                  />
                </div>
                <div className="carousel-slide">
                  <img
                    src={costu38}
                    alt="costu38"
                    style={{ maxWidth: '135px' }}
                  />
                </div>
                <div className="carousel-slide">
                  <img
                    src={costu59}
                    alt="costu59"
                    style={{ maxWidth: '135px' }}
                  />
                </div>
                <div className="carousel-slide">
                  <img
                    src={costu15}
                    alt="costu15"
                    style={{ maxWidth: '135px' }}
                  />
                </div>
                <div className="carousel-slide">
                  <img
                    src={costu4}
                    alt="costu4"
                    style={{ maxWidth: '135px' }}
                  />
                </div>
                <div className="carousel-slide">
                  <img
                    src={costu45}
                    alt="costu45"
                    style={{ maxWidth: '135px' }}
                  />
                </div>
                <div className="carousel-slide">
                  <img
                    src={costu46}
                    alt="costu46"
                    style={{ maxWidth: '135px' }}
                  />
                </div>
                <div className="carousel-slide">
                  <img
                    src={costu60}
                    alt="costu60"
                    style={{ maxWidth: '135px' }}
                  />
                </div>
                <div className="carousel-slide">
                  <img
                    src={costu48}
                    alt="costu48"
                    style={{ maxWidth: '135px' }}
                  />
                </div>
                <div className="carousel-slide">
                  <img
                    src={costu49}
                    alt="costu49"
                    style={{ maxWidth: '135px' }}
                  />
                </div>
                <div className="carousel-slide">
                  <img
                    src={costu50}
                    alt="costu50"
                    style={{ maxWidth: '135px' }}
                  />
                </div>
                <div className="carousel-slide">
                  <img
                    src={costu51}
                    alt="costu51"
                    style={{ maxWidth: '135px' }}
                  />
                </div>
                <div className="carousel-slide">
                  <img
                    src={costu52}
                    alt="costu52"
                    style={{ maxWidth: '135px' }}
                  />
                </div>
                <div className="carousel-slide">
                  <img
                    src={costu61}
                    alt="costu61"
                    style={{ maxWidth: '135px' }}
                  />
                </div>
                <div className="carousel-slide">
                  <img
                    src={costu62}
                    alt="costu62"
                    style={{ maxWidth: '135px' }}
                  />
                </div>
                <div className="carousel-slide">
                  <img
                    src={costu63}
                    alt="costu63"
                    style={{ maxWidth: '175px' }}
                  />
                </div>
              </Slider>
            </div>
          </>
        )}
      </div>
    </div>
  )
}

export default Technologies
